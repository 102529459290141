import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Button} from '@mui/material'

function FormBook({ handleNext }) {
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language"); // Intentamos obtener el idioma de localStorage
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Si existe, lo usamos
    } else {
      // Si no existe, podemos usar un idioma predeterminado (por ejemplo, 'en')
      i18n.changeLanguage("en");
    }
  }, [i18n]);
  const [formData, setFormData] = useState({
    currency: "USD",
    isReturn: false,
    vehicles: "SUBURBAN",
    passengers: "1",
    departureDate: "2024-10-04",
    departureTime: "01:01",
    returnDate: "2024-10-04",
    returnTime: "00:00",
    from: "(SJD) Los Cabos international Airpot",
    to: "BARCELO GRAN FARO",
    price: 0, // Inicializamos el precio como 0
  });

  const convertToCurrency = (amount, currency) => {
    const exchangeRate = 20.14; // Ejemplo: 1 USD = 18.5 MXN
    if (currency === "MXN") {
      return (amount * exchangeRate).toFixed(2);
    }
    return amount.toFixed(2); // Si es USD, se deja igual
  };

  // Calcular y actualizar el precio basado en el vehículo y si es retorno
  const calculatePrice = useCallback(() => {
    let basePrice = 0;
    const { vehicles, isReturn, currency } = formData;
    // San Jose del Cabo
    if (formData.to === "BARCELO GRAN FARO" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;
    } else if (formData.to === "BARCELO GRAN FARO" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;
    }

    //Corridor
    if (formData.to === "BREATHLESS RESORT SPA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;
    } else if (formData.to === "BREATHLESS RESORT SPA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;
    }

    //Puerto Los Cabos
    if (formData.to === "BAJA POINT" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;
    } else if (formData.to === "BAJA POINT" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;
    }

    //Encanto la Laguna
    if (
      formData.to === "BAHIA HOTEL AND BEACH CLUB" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 160 : 105;
    } else if (
      formData.to === "BAHIA HOTEL AND BEACH CLUB" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;
    }

    //Cabo San Lucas
    if (formData.to === "BUNGALOWS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;
    } else if (formData.to === "BUNGALOWS" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;
    }

    //Pacific
    if (formData.to === "CABO AZUL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;
    } else if (formData.to === "CABO AZUL" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;
    }

    //Todos Santos
    if (formData.to === "CABO TORTUGA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;
    } else if (formData.to === "CABO TORTUGA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;
    }
    //Los Barriles
    if (formData.to === "CABO BELLO RESIDENTIAL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;
    } else if (formData.to === "CABO BELLO RESIDENTIAL" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;
    }

    //La Paz
    if (
      formData.to === "CABO DEL SOL RESIDENTIAL AREA" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 160 : 105;
    } else if (
      formData.to === "CABO DEL SOL RESIDENTIAL AREA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;
    }
    //Diamante
    if (formData.to === "CABO PEDREGAL HOTEL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;
    } else if (formData.to === "CABO PEDREGAL HOTEL" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;
    }
    //San Jose del Cabo - Tourist Corridor

    if (formData.to === "CABO SAN LUCAS INN" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "CABO SAN LUCAS INN" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //San Jose del Cabo - Cabo San Lucas

    if (formData.to === "CABO SURF" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "CABO SURF" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }

    //San Jose del Cabo - Pacific

    if (
      formData.to === "CABO VILLAS (CORAZON CABO RESORT SPA)" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 160 : 105;

    } else if (
      formData.to === "CABO VILLAS (CORAZON CABO RESORT SPA)" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;

    }

    //San Jose del Cabo - Puerto Los Cabos

    if (
      formData.to === "CASA DORADA AT MEDANO BEACH" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 160 : 105;

    } else if (
      formData.to === "CASA DORADA AT MEDANO BEACH" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;

    }

    //San Jose del Cabo - Los Barriles

    if (formData.to === "CASA NATALIA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "CASA NATALIA" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;
      
    }
    //CASA PABLITO

    if (formData.to === "CASA PABLITO" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "CASA PABLITO" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //CASA RAFAEL

    if (formData.to === "CASA RAFAEL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "CASA RAFAEL" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    if (formData.to === "CASCADAS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "CASCADAS" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //CHILENO BAY

    if (formData.to === "CHILENO BAY" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "CHILENO BAY" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }

    //CLUB LA COSTA

    if (formData.to === "CLUB LA COSTA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "CLUB LA COSTA" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    //CLUB REGINA

    if (formData.to === "CLUB REGINA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "CLUB REGINA" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //COMFORT INN

    if (formData.to === "COMFORT INN" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "COMFORT INN" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //CORAL BAJA

    if (formData.to === "CORAL BAJA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "CORAL BAJA" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }

    //DIAMANTE

    if (formData.to === "DIAMANTE" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 180 : 115;

    } else if (formData.to === "DIAMANTE" && vehicles === "VAN") {
      basePrice = isReturn ? 200 : 135;

    }

    //DREAMS

    if (formData.to === "DREAMS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "DREAMS" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //EL AMEYAL

    if (formData.to === "EL AMEYAL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "EL AMEYAL" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //PUEBLO BONITO PACIFICA

    if (formData.to === "PUEBLO BONITO PACIFICA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 180 : 115;

    } else if (formData.to === "PUEBLO BONITO PACIFICA" && vehicles === "VAN") {
      basePrice = isReturn ? 200 : 135;

    }

    //PUEBLO BONITO ROSE

    if (formData.to === "PUEBLO BONITO ROSE" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "PUEBLO BONITO ROSE" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //PUEBLO BONITO SUNSET BEACH

    if (
      formData.to === "PUEBLO BONITO SUNSET BEACH" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 180 : 115;

    } else if (
      formData.to === "PUEBLO BONITO SUNSET BEACH" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 200 : 135;

    }

    //PUERTO LOS CABOS RESIDENTIAL AREA

    if (
      formData.to === "PUERTO LOS CABOS RESIDENTIAL AREA" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 140 : 95;

    } else if (
      formData.to === "PUERTO LOS CABOS RESIDENTIAL AREA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 160 : 115;

    }

    //QUINTA DEL SOL

    if (formData.to === "QUINTA DEL SOL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "QUINTA DEL SOL" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //QUERENCIA RESIDENTIAL AREA

    if (
      formData.to === "QUERENCIA RESIDENTIAL AREA" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 140 : 95;

    } else if (
      formData.to === "QUERENCIA RESIDENTIAL AREA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 160 : 115;

    }

    //QUIVIRA RESIDENTIAL

    if (formData.to === "QUIVIRA RESIDENTIAL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 180 : 115;

    } else if (formData.to === "QUIVIRA RESIDENTIAL" && vehicles === "VAN") {
      basePrice = isReturn ? 200 : 135;
    
    }

    //RITZ ZADUN

    if (formData.to === "RITZ ZADUN" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "RITZ ZADUN" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //RIU BAJA (ALL ADULTS RESORT)

    if (
      formData.to === "RIU BAJA (ALL ADULTS RESORT)" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 160 : 105;

    } else if (
      formData.to === "RIU BAJA (ALL ADULTS RESORT)" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;

    }

    //RIU PALACE

    if (formData.to === "RIU PALACE" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "RIU PALACE" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //RIU SANTA FE

    if (formData.to === "RIU SANTA FE" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "RIU SANTA FE" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //ROYAL DE CAMERON

    if (formData.to === "ROYAL DE CAMERON" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "ROYAL DE CAMERON" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    //ROYAL PARK

    if (formData.to === "ROYAL PARK" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "ROYAL PARK" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    //ROYAL SOLARIS

    if (formData.to === "ROYAL SOLARIS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "ROYAL SOLARIS" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }

    //SANDOS FINISTERRA

    if (formData.to === "SANDOS FINISTERRA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "SANDOS FINISTERRA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //SAMPAGUITA

    if (formData.to === "SAMPAGUITA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "SAMPAGUITA" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }

    //SECRETS PUERTO LOS CABOS

    if (formData.to === "SECRETS PUERTO LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (
      formData.to === "SECRETS PUERTO LOS CABOS" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 160 : 115;

    }

    //SEVEN CROWN

    if (formData.to === "SEVEN CROWN" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "SEVEN CROWN" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //SHERATON HOTEL

    if (formData.to === "SHERATON HOTEL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "SHERATON HOTEL" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //SIESTA SUITES

    if (formData.to === "SIESTA SUITES" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "SIESTA SUITES" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //SIRENA DEL MAR

    if (formData.to === "SIRENA DEL MAR" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "SIRENA DEL MAR" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //SOLAZ LOS CABOS

    if (formData.to === "SOLAZ LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "SOLAZ LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }

    //SOLMAR

    if (formData.to === "SOLMAR" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "SOLMAR" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //SOLEADO BEACH AND SPA
    if (formData.to === "SOLEADO BEACH AND SPA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "SOLEADO BEACH AND SPA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //SUITES LAS PALMAS
    if (formData.to === "SUITES LAS PALMAS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "SUITES LAS PALMAS" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }

    //TERRASOL
    if (formData.to === "TERRASOL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "TERRASOL" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //TESORO LOS CABOS
    if (formData.to === "TESORO LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "TESORO LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //THE CAPE THOMPSON
    if (formData.to === "THE CAPE THOMPSON" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "THE CAPE THOMPSON" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //THE GRAND MAYAN AT VIDANTA
    if (
      formData.to === "THE GRAND MAYAN AT VIDANTA" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 120 : 85;

    } else if (
      formData.to === "THE GRAND MAYAN AT VIDANTA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 140 : 105;

    }

    //THE WESTIN RESORT AND SPA
    if (formData.to === "THE WESTIN RESORT AND SPA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (
      formData.to === "THE WESTIN RESORT AND SPA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 160 : 115;

    }
    //EL ENCANTO INN
    if (formData.to === "EL ENCANTO INN" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "EL ENCANTO INN" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }

    //EL ENCANTO DE LA LAGUNA
    if (formData.to === "EL ENCANTO DE LA LAGUNA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "EL ENCANTO DE LA LAGUNA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }

    //EL ENCANTO DE LA LAGUNA
    if (formData.to === "EL GANZO" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "EL GANZO" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }

    //EL ZALATE CONDOS
    if (formData.to === "EL ZALATE CONDOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "EL ZALATE CONDOS" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }

    //ESPERANZA
    if (formData.to === "ESPERANZA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "ESPERANZA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    
    //FAIRFIELD INN BY MARRIOT
    if (formData.to === "FAIRFIELD INN BY MARRIOT" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (
      formData.to === "FAIRFIELD INN BY MARRIOT" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;

    }
    //ESPERANZA
    if (formData.to === "FIESTA AMERICANA GRAND" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "FIESTA AMERICANA GRAND" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //ESPERANZA
    if (formData.to === "FLORA FARMS RESTAURANT" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "FLORA FARMS RESTAURANT" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //ESPERANZA
    if (
      formData.to === "FOUR SEASON RESORT LOS CABOS" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 560 : 305;

    } else if (
      formData.to === "FOUR SEASON RESORT LOS CABOS" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 580 : 325;

    }
    //ESPERANZA
    if (formData.to === "GARZA BLANCA RESORT" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "GARZA BLANCA RESORT" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //ESPERANZA
    if (
      formData.to === "GRAN SOLMAR PACIFIC DUNES GOLF AND SPA" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 170 : 115;

    } else if (
      formData.to === "GRAN SOLMAR PACIFIC DUNES GOLF AND SPA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 190 : 135;

    }
    //ESPERANZA
    if (
      formData.to === "GRAND SOLMAR RANCHO SAN LUCAS" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 170 : 115;

    } else if (
      formData.to === "GRAND SOLMAR RANCHO SAN LUCAS" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 190 : 135;

    }
    //ESPERANZA
    if (
      formData.to === "GRANS SOLMAR LANDS END RESORT SPA" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 150 : 105;

    } else if (
      formData.to === "GRANS SOLMAR LANDS END RESORT SPA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 170 : 125;

    }
    //ESPERANZA
    if (formData.to === "GRAN VELAS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "GRAN VELAS" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //ESPERANZA
    if (formData.to === "HACIENDA BEACH" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "HACIENDA BEACH" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //ESPERANZA
    if (
      formData.to === "HACIENDA DEL MAR TIME SHARE" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 160 : 105;

    } else if (
      formData.to === "HACIENDA DEL MAR TIME SHARE" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;

    }
    //ESPERANZA
    if (formData.to === "HACIENDA ENCANTADA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "HACIENDA ENCANTADA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //ESPERANZA
    if (
      formData.to === "HAMPTON INN SUITES BY HILTON" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 140 : 95;

    } else if (
      formData.to === "HAMPTON INN SUITES BY HILTON" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 160 : 115;

    }
    //ESPERANZA
    if (formData.to === "HARD ROCK HOTEL LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 180 : 115;

    } else if (
      formData.to === "HARD ROCK HOTEL LOS CABOS" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 200 : 135;

    }
    //ESPERANZA
    if (formData.to === "HILTON LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "HILTON LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //ESPERANZA
    if (formData.to === "HOLIDAY LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "HOLIDAY LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //ESPERANZA
    if (formData.to === "HOLIDAY INN EXPRESS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "HOLIDAY INN EXPRESS" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //ESPERANZA
    if (formData.to === "HOTEL CASTILLO BLARNEY" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "HOTEL CASTILLO BLARNEY" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //ESPERANZA
    if (formData.to === "HOTEL HACIENDA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "HOTEL HACIENDA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    //ESPERANZA
    if (formData.to === "HYATT PLACE" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "HYATT PLACE" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    //ESPERANZA
    if (formData.to === "HYATT ZIVA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "HYATT ZIVA" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    //ESPERANZA
    if (formData.to === "JW MARRIOTT RESORT SPA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "JW MARRIOTT RESORT SPA" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //ESPERANZA
    if (formData.to === "KRISTAL GRAND LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "KRISTAL GRAND LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    //ESPERANZA
    if (formData.to === "LA JOLLA CONDOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "LA JOLLA CONDOS" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    //ESPERANZA
    if (formData.to === "LA PACIFICA BY HILTON" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "LA PACIFICA BY HILTON" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    //ESPERANZA
    if (formData.to === "LA PAZ" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 510 : 280;

    } else if (formData.to === "LA PAZ" && vehicles === "VAN") {
      basePrice = isReturn ? 530 : 300;

    }
    if (formData.to === "LAS OLAS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "LAS OLAS" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    if (formData.to === "LAS VENTANAS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "LAS VENTANAS" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    if (formData.to === "LE BLANC LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "LE BLANC LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    if (formData.to === "LOS BARRILES" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 380 : 215;

    } else if (formData.to === "LOS BARRILES" && vehicles === "VAN") {
      basePrice = isReturn ? 400 : 235;

    }
    if (formData.to === "LOS CABOS GOLF RESORT" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "LOS CABOS GOLF RESORT" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "LOS MILAGROS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "LOS MILAGROS" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "LOS PATIOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "LOS PATIOS" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "MAÑANITAS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "MAÑANITAS" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    if (formData.to === "MAR DEL CABO" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "MAR DEL CABO" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }

    if (
      formData.to === "MARBELLA SUITES EN LA PLAYA" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 140 : 95;

    } else if (
      formData.to === "MARBELLA SUITES EN LA PLAYA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 160 : 115;

    }
    if (formData.to === "MARINA CABO PLAZA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "MARINA CABO PLAZA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "MARINA FIESTA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "MARINA FIESTA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "MARINA SOL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "MARINA SOL" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "MARINA VIEW VILLAS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "MARINA VIEW VILLAS" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "MARISOL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "MARISOL" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "ME CABO" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "ME CABO" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "MIKONOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "MIKONOS" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    if (formData.to === "MISIONES DEL CABO" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 70;

    } else if (formData.to === "MISIONES DEL CABO" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 70;

    }
    if (formData.to === "MONTAGE LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "MONTAGE LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    if (
      formData.to === "MONTECRISTO RESIDENTIAL AREA" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 180 : 115;

    } else if (
      formData.to === "MONTECRISTO RESIDENTIAL AREA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 200 : 135;

    }
    if (formData.to === "NOBU HOTEL LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 180 : 115;

    } else if (formData.to === "NOBU HOTEL LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 200 : 135;

    }
    if (formData.to === "ONE AND ONLY PALMILLA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "ONE AND ONLY PALMILLA" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    if (formData.to === "PARADISUS LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "PARADISUS LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    if (formData.to === "PEDREGAL RESIDENTIAL AREA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (
      formData.to === "PEDREGAL RESIDENTIAL AREA" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "PLAYA GRANDE" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "PLAYA GRANDE" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (
      formData.to === "PLAZA NAUTICA CONDOMINIUMS HOTEL" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 160 : 105;

    } else if (
      formData.to === "PLAZA NAUTICA CONDOMINIUMS HOTEL" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "PORTOFINO HOTEL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "PORTOFINO HOTEL" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "POSADA REAL" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "POSADA REAL" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    if (formData.to === "PUEBLO BONITO LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "PUEBLO BONITO LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "TODO SANTOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 380 : 215;

    } else if (formData.to === "TODO SANTOS" && vehicles === "VAN") {
      basePrice = isReturn ? 400 : 235

    }
    if (formData.to === "TROPICANA INN" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "TROPICANA INN" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    if (formData.to === "VICEROY LOS CABOS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 120 : 85;

    } else if (formData.to === "VICEROY LOS CABOS" && vehicles === "VAN") {
      basePrice = isReturn ? 140 : 105;

    }
    if (formData.to === "VILLA DE ARCO" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "VILLA DE ARCO" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "VILLA DEL PALMAR" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "VILLA DEL PALMAR" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "VILLA LA ESTANCIA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "VILLA LA ESTANCIA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "VILLA VALENCIA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "VILLA VALENCIA" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }
    if (
      formData.to === "VILLA VALENCIA RESORT AND SPA LOS CABOS" &&
      vehicles === "SUBURBAN"
    ) {
      basePrice = isReturn ? 160 : 105;

    } else if (
      formData.to === "VILLA VALENCIA RESORT AND SPA LOS CABOS" &&
      vehicles === "VAN"
    ) {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "VILLA SERENA" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "VILLA SERENA" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "VISTA VELA (PHASE 1)" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 105;

    } else if (formData.to === "VISTA VELA (PHASE 1)" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "VISTA VELA (PHASE 2)" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "VISTA VELA (PHASE 2)" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "VISTA VELA (PHASE 3)" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "VISTA VELA (PHASE 3)" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "WALDORF ASTURIAS" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 160 : 105;

    } else if (formData.to === "WALDORF ASTURIAS" && vehicles === "VAN") {
      basePrice = isReturn ? 180 : 125;

    }
    if (formData.to === "ZOETRY CASA DEL MAR" && vehicles === "SUBURBAN") {
      basePrice = isReturn ? 140 : 95;

    } else if (formData.to === "ZOETRY CASA DEL MAR" && vehicles === "VAN") {
      basePrice = isReturn ? 160 : 115;

    }

    // Actualizar el estado con el precio calculado
    const convertedPrice = convertToCurrency(basePrice, currency);
    setFormData((prevData) => ({
      ...prevData,
      price: convertedPrice, // Guardamos el precio calculado en formData
    }));
  }, [formData.currency, formData.isReturn, formData.to, formData.vehicles]);

  // Llamar a calculatePrice cuando alguna de las dependencias cambie
  useEffect(() => {
    calculatePrice();
  }, [
    formData.currency,
    formData.isReturn,
    formData.to,
    formData.vehicle,
    calculatePrice,
  ]);

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      localStorage.setItem("formData", JSON.stringify(formData));
      console.log("Datos guardados en localStorage", formData);

      handleNext();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  // Función para manejar el cambio de moneda
  const handleCurrencyChange = (currency) => {
    setFormData((prevData) => ({ ...prevData, currency }));
  };
  const {
    currency,
    isReturn,
    vehicles,
    passengers,
    departureDate,
    departureTime,
    returnDate,
    returnTime,
    from,
    to,
  } = formData;
  const areasFrom = [
    "(SJD) Los Cabos international Airport",
    "(MMSL) Cabo San Lucas International Airport",
  ];
  const areas = [
    "BARCELO GRAN FARO",
    "BREATHLESS RESORT SPA",
    "BAJA POINT",
    "BAHIA HOTEL AND BEACH CLUB",
    "BUNGALOWS",
    "CABO AZUL",
    "CABO TORTUGA",
    "CABO BELLO RESIDENTIAL",
    "CABO DEL SOL RESIDENTIAL AREA",
    "CABO PEDREGAL HOTEL",
    "CABO SAN LUCAS INN",
    "CABO SURF",
    "CABO VILLAS (CORAZON CABO RESORT SPA)",
    "CASA DORADA AT MEDANO BEACH",
    "CASA NATALIA",
    "CASA PABLITO",
    "CASA RAFAEL",
    "CASCADAS",
    "CHILENO BAY",
    "CLUB LA COSTA",
    "CLUB REGINA",
    "COMFORT INN",
    "CORAL BAJA",
    "DIAMANTE",
    "DREAMS",
    "EL AMEYAL",
    "PUEBLO BONITO PACIFICA",
    "PUEBLO BONITO ROSE",
    "PUEBLO BONITO SUNSET BEACH",
    "PUERTO LOS CABOS RESIDENTIAL AREA",
    "QUINTA DEL SOL",
    "QUERENCIA RESIDENTIAL AREA",
    "UIVIRA RESIDENTIAL",
    "RITZ ZADUN",
    "RIU BAJA (ALL ADULTS RESORT)",
    "RIU PALACE",
    "RIU SANTA FE",
    "ROYAL DE CAMERON",
    "ROYAL PARK",
    "ROYAL SOLARIS",
    "SANDOS FINISTERRA",
    "SAMPAGUITA",
    "SECRETS PUERTO LOS CABOS",
    "SEVEN CROWN",
    "SHERATON HOTEL",
    "SIESTA SUITES",
    "SIRENA DEL MAR",
    "SOLAZ LOS CABOS",
    "SOLMAR",
    "SOLEADO BEACH AND SPA",
    "SUITES LAS PALMAS",
    "TERRASOL",
    "TESORO LOS CABOS",
    "THE CAPE THOMPSON",
    "THE GRAND MAYAN AT VIDANTA",
    "THE WESTIN RESORT AND SPA",
    "EL ENCANTO INN",
    "EL ENCANTO DE LA LAGUNA",
    "EL GANZO",
    "EL ZALATE CONDOS",
    "ESPERANZA",

    "FAIRFIELD INN BY MARRIOT",

    "FIESTA AMERICANA GRAND",
    "FLORA FARMS RESTAURANT",
    "FOUR SEASON RESORT LOS CABOS",
    "GARZA BLANCA RESORT",
    "GRAN SOLMAR PACIFIC DUNES GOLF AND SPA",
    "GRAND SOLMAR RANCHO SAN LUCAS",
    "GRANS SOLMAR LANDS END RESORT SPA",
    "GRAN VELAS",
    "HACIENDA BEACH",
    "HACIENDA DEL MAR TIME SHARE",
    "HACIENDA ENCANTADA",
    "HAMPTON INN SUITES BY HILTON",
    "HARD ROCK HOTEL LOS CABOS",
    "HILTON LOS CABOS",
    "HOLIDAY LOS CABOS",
    "HOLIDAY INN EXPRESS",
    "HOTEL CASTILLO BLARNEY",
    "HOTEL HACIENDA",
    "HYATT PLACE",
    "HYATT ZIVA",
    "JW MARRIOTT RESORT SPA",
    "KRISTAL GRAND LOS CABOS",
    "LA JOLLA CONDOS",
    "LA PACIFICA BY HILTON",
    "LA PAZ",
    "LAS OLAS",
    "LAS VENTANAS",
    "LE BLANC LOS CABOS",
    "LOS BARRILES",
    "LOS CABOS GOLF RESORT",
    "LOS MILAGROS",
    "LOS PATIOS",
    "MAÑANITAS",
    "MAR DEL CABO",
    "MARBELLA SUITES EN LA PLAYA",
    "MARINA CABO PLAZA",
    "MARINA FIESTA",
    "MARINA SOL",
    "MARINA VIEW VILLAS",
    "MARISOL",
    "ME CABO",
    "MIKONOS",
    "MISIONES DEL CABO",
    "MONTAGE LOS CABOS",
    "MONTECRISTO RESIDENTIAL AREA",
    "NOBU HOTEL LOS CABOS",
    "ONE AND ONLY PALMILLA",
    "PARADISUS LOS CABOS",
    "PEDREGAL RESIDENTIAL AREA",
    "PLAYA GRANDE",
    "PLAZA NAUTICA CONDOMINIUMS HOTEL",
    "PORTOFINO HOTEL",
    "POSADA REAL",
    "PUEBLO BONITO LOS CABOS",
    "TODO SANTOS",
    "TROPICANA INN",
    "VICEROY LOS CABOS",
    "VILLA DE ARCO",
    "VILLA DEL PALMAR",
    "VILLA LA ESTANCIA",
    "VILLA VALENCIA",
    "VILLA VALENCIA RESORT AND SPA LOS CABOS",
    "VILLA SERENA",
    "VISTA VELA (PHASE 1)",
    "VISTA VELA (PHASE 2)",
    "VISTA VELA (PHASE 3)",
    "WALDORF ASTURIAS",
    "ZOETRY CASA DEL MAR",
  ];

  return (
    <div className="form-container">
      <div className="booking-container">
        <h2>Book your transfer service from Loreto to Cabo</h2>
        <form onSubmit={handleSubmit}>
          {/* Currency Toggle */}
          <div className="currency-toggle">
            {["USD", "MXN"].map((curr) => (
              <button
                key={curr}
                type="button"
                className={currency === curr ? "active" : ""}
                onClick={() => handleCurrencyChange(curr)}
              >
                {curr}
              </button>
            ))}
          </div>

          {/* From */}
          <div className="form-group">
            <label>{t("form.from")}</label>
            <select name="from" value={from} onChange={handleChange}>
              {areasFrom.map((area) => (
                <option key={area} value={area}>
                  {area}
                </option>
              ))}
            </select>
          </div>

          {/* To */}
          <div className="form-group">
            <label>{t("form.to")}</label>
            <select name="to" value={to} onChange={handleChange}>
              {areas.map((area) => (
                <option key={area} value={area}>
                  {area}
                </option>
              ))}
            </select>
          </div>

          {/* Departure Date & Time */}
          <div className="d-flex gap-2">

          <div className="form-group">
            <label>{t("form.departure_date")}</label>
            <input
              type="date"
              name="departureDate"
              value={departureDate}
              onChange={handleChange}
              />
          </div>

          <div className="form-group">
            <label>{t("form.departure_time")}</label>
            <input
              type="time"
              name="departureTime"
              value={departureTime}
              onChange={handleChange}
              />
          </div>
              </div>

          <div className="form-group">
            <label>{t("form.vehicle")}</label>
            <select onChange={handleChange} name="vehicles" value={vehicles}>
              <option value="SUBURBAN">SUBURBAN</option>
              <option value="VAN">VAN</option>
            </select>
          </div>

          {/* Passengers */}
          <div className="form-group">
            <label>{t("form.passengers")}</label>
            <select
              type="number"
              name="passengers"
              value={passengers}
              onChange={handleChange}
            >
              {vehicles === "SUBURBAN" &&
                [1, 2, 3, 4, 5].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
        
              {vehicles === "VAN" &&
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}

            
            </select>
          </div>

          {/* Return Toggle */}

          {to === "BARCELO GRAN FARO" ||
          to === "BREATHLESS RESORT SPA" ||
          to === "BAJA POINT" ||
          to === "BAHIA HOTEL AND BEACH CLUB" ||
          to === "BUNGALOWS" ||
          to === "CABO AZUL" ||
          to === "Los Barriles" ||
          to === "CABO TORTUGA" ||
          to === "CABO BELLO RESIDENTIAL" ||
          to === "CABO DEL SOL RESIDENTIAL AREA" ||
          to === "CABO PEDREGAL HOTEL" ||
          to === "CABO SAN LUCAS INN" ||
          to === "CABO SURF" ||
          to === "CABO VILLAS (CORAZON CABO RESORT SPA)" ||
          to === "CASA DORADA AT MEDANO BEACH" ||
          to === "CASA NATALIA" ||
          to === "CASA PABLITO" ||
          to === "CASA RAFAEL" ||
          to === "CASCADAS" ||
          to === "CHILENO BAY" ||
          to === "CLUB LA COSTA" ||
          to === "CLUB REGINA" ||
          to === "COMFORT INN" ||
          to === "CORAL BAJA" ||
          to === "DIAMANTE" ||
          to === "DREAMS" ||
          to === "EL AMEYAL" ||
          to === "PUEBLO BONITO PACIFICA" ||
          to === "PUEBLO BONITO ROSE" ||
          to === "PUEBLO BONITO SUNSET BEACH" ||
          to === "PUERTO LOS CABOS RESIDENTIAL AREA" ||
          to === "QUINTA DEL SOL" ||
          to === "QUERENCIA RESIDENTIAL AREA" ||
          to === "UIVIRA RESIDENTIAL" ||
          to === "RITZ ZADUN" ||
          to === "RIU BAJA (ALL ADULTS RESORT)" ||
          to === "RIU PALACE" ||
          to === "RIU SANTA FE" ||
          to === "ROYAL DE CAMERON" ||
          to === "ROYAL PARK" ||
          to === "ROYAL SOLARIS" ||
          to === "SANDOS FINISTERRA" ||
          to === "SAMPAGUITA" ||
          to === "SECRETS PUERTO LOS CABOS" ||
          to === "SEVEN CROWN" ||
          to === "SHERATON HOTEL" ||
          to === "SIESTA SUITES" ||
          to === "SIRENA DEL MAR" ||
          to === "SOLAZ LOS CABOS" ||
          to === "SOLMAR" ||
          to === "SOLEADO BEACH AND SPA" ||
          to === "SUITES LAS PALMAS" ||
          to === "TERRASOL" ||
          to === "TESORO LOS CABOS" ||
          to === "THE CAPE THOMPSON" ||
          to === "THE GRAND MAYAN AT VIDANTA" ||
          to === "THE WESTIN RESORT AND SPA" ||
          to === "EL ENCANTO INN" ||
          to === "EL ENCANTO DE LA LAGUNA" ||
          to === "EL GANZO" ||
          to === "EL ZALATE CONDOS" ||
          to === "ESPERANZA" ||
          to === "FAIRFIELD INN BY MARRIOT" ||
          to === "FIESTA AMERICANA GRAND" ||
          to === "FLORA FARMS RESTAURANT" ||
          to === "FOUR SEASON RESORT LOS CABOS" ||
          to === "GARZA BLANCA RESORT" ||
          to === "GRAN SOLMAR PACIFIC DUNES GOLF AND SPA" ||
          to === "GRAND SOLMAR RANCHO SAN LUCAS" ||
          to === "GRANS SOLMAR LANDS END RESORT SPA" ||
          to === "GRAN VELAS" ||
          to === "HACIENDA BEACH" ||
          to === "HACIENDA DEL MAR TIME SHARE" ||
          to === "HACIENDA ENCANTADA" ||
          to === "HAMPTON INN SUITES BY HILTON" ||
          to === "HARD ROCK HOTEL LOS CABOS" ||
          to === "HILTON LOS CABOS" ||
          to === "HOLIDAY LOS CABOS" ||
          to === "HOLIDAY INN EXPRESS" ||
          to === "HOTEL CASTILLO BLARNEY" ||
          to === "HOTEL HACIENDA" ||
          to === "HYATT PLACE" ||
          to === "HYATT ZIVA" ||
          to === "JW MARRIOTT RESORT SPA" ||
          to === "LKRISTAL GRAND LOS CABOS" ||
          to === "LA JOLLA CONDOS" ||
          to === "LA PACIFICA BY HILTON" ||
          to === "LA PAZ" ||
          to === "LAS OLAS" ||
          to === "LAS VENTANAS" ||
          to === "LE BLANC LOS CABOS" ||
          to === "LOS BARRILES" ||
          to === "LOS CABOS GOLF RESORT" ||
          to === "LOS MILAGROS" ||
          to === "LOS PATIOS" ||
          to === "MAÑANITAS" ||
          to === "MAR DEL CABO" ||
          to === "MARBELLA SUITES EN LA PLAYA" ||
          to === "MARINA CABO PLAZA" ||
          to === "MARINA FIESTA" ||
          to === "MARINA SOL" ||
          to === "MARINA VIEW VILLAS" ||
          to === "MARISOL" ||
          to === "ME CABO" ||
          to === "MIKONOS" ||
          to === "MISIONES DEL CABO" ||
          to === "MONTAGE LOS CABOS" ||
          to === "MONTECRISTO RESIDENTIAL AREA" ||
          to === "NOBU HOTEL LOS CABOS" ||
          to === "ONE AND ONLY PALMILLA" ||
          to === "PARADISUS LOS CABOS" ||
          to === "PEDREGAL RESIDENTIAL AREA" ||
          to === "PLAYA GRANDE" ||
          to === "PLAZA NAUTICA CONDOMINIUMS HOTEL" ||
          to === "PORTOFINO HOTEL" ||
          to === "POSADA REAL" ||
          to === "PUEBLO BONITO LOS CABOS" ||
          to === "TODO SANTOS" ||
          to === "TROPICANA INN" ||
          to === "VICEROY LOS CABOS" ||
          to === "VILLA DE ARCO" ||
          to === "VILLA DEL PALMAR" ||
          to === "VILLA LA ESTANCIA" ||
          to === "VILLA VALENCIA" ||
          to === "VILLA VALENCIA RESORT AND SPA LOS CABOS" ||
          to === "VILLA SERENA" ||
          to === "VISTA VELA (PHASE 1)" ||
          to === "VISTA VELA (PHASE 2)" ||
          to === "VISTA VELA (PHASE 3)" ||
          to === "WALDORF ASTURIAS" ||
          to === "ZOETRY CASA DEL MAR" 
        
         ? (
            <>
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  id="return-toggle"
                  name="isReturn"
                  checked={isReturn}
                  onChange={handleChange}
                />
                <label htmlFor="return-toggle">
                  <span className="switch"></span>
                  {t("form.return")}
                </label>
              </div>

              {/* Return Date & Time */}
              {isReturn && (
                <>
                <div className="d-flex gap-2">

                  <div className="form-group">
                    <label>{t("form.return_date")}</label>
                    <input
                      type="date"
                      name="returnDate"
                      value={returnDate}
                      onChange={handleChange}
                    />
                  </div>


                  <div className="form-group">
                    <label>{t("form.return_time")}</label>
                    <input
                      type="time"
                      name="returnTime"
                      value={returnTime}
                      onChange={handleChange}
                    />
                  </div>
                  </div>

                </>
              )}
            </>
          ) : null}

          <div className="form-group">
            {/* San Jose del Cabo */}
            {to === "BARCELO GRAN FARO" && vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "BARCELO GRAN FARO" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* Corridor */}

            {to === "BREATHLESS RESORT SPA" && vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "BREATHLESS RESORT SPA" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* Puerto Los Cabos */}

            {to === "BAJA POINT" && vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "BAJA POINT" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* Encanto la Laguna */}

            {to === "BAHIA HOTEL AND BEACH CLUB" && vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "BAHIA HOTEL AND BEACH CLUB" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* Cabo San Lucas */}

            {to === "BUNGALOWS" && vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "BUNGALOWS" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* Pacific */}

            {to === "CABO AZUL" && vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "CABO AZUL" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* Todos Santos */}

            {to === "CABO TORTUGA" && vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "CABO TORTUGA" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* Los Barriles */}

            {to === "CABO BELLO RESIDENTIAL" && vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "CABO BELLO RESIDENTIAL" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* La Paz */}

            {to === "CABO DEL SOL RESIDENTIAL AREA" &&
            vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "CABO DEL SOL RESIDENTIAL AREA" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* Diamante */}

            {to === "CABO PEDREGAL HOTEL" && vehicles === "SUBURBAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {to === "CABO PEDREGAL HOTEL" && vehicles === "VAN" ? (
              isReturn ? (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  ${formData.price} {currency}
                </h1>
              )
            ) : null}

            {/* San Jose del Cabo - Tourist Corridor */}

            {to === "CABO SAN LUCAS INN" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CABO SAN LUCAS INN" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/* San Jose del Cabo - Cabo San Lucas*/}

            {to === "CABO SURF" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CABO SURF" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/* San Jose del Cabo - Cabo San Lucas*/}

            {to === "CABO VILLAS (CORAZON CABO RESORT SPA)" &&
            vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CABO VILLAS (CORAZON CABO RESORT SPA)" &&
            vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/* San Jose del Cabo - Puerto Los Cabos*/}

            {to === "CASA DORADA AT MEDANO BEACH" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CASA DORADA AT MEDANO BEACH" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/* San Jose del Cabo - Los Barriles*/}

            {to === "CASA NATALIA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CASA NATALIA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/* CASA PABLITO*/}

            {to === "CASA PABLITO" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CASA PABLITO" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/* CASA RAFAEL*/}

            {to === "CASA RAFAEL" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CASA RAFAEL" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*CASCADAS*/}

            {to === "CASCADAS" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CASCADAS" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*CHILENO BAY*/}

            {to === "CHILENO BAY" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CHILENO BAY" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*CLUB LA COSTA*/}

            {to === "CLUB LA COSTA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CLUB LA COSTA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*CLUB REGINA*/}

            {to === "CLUB REGINA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CLUB REGINA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*COMFORT INN*/}

            {to === "COMFORT INN" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "COMFORT INN" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*CORAL BAJA*/}

            {to === "CORAL BAJA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "CORAL BAJA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*DIAMANTE*/}

            {to === "DIAMANTE" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "DIAMANTE" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*DREAMS*/}

            {to === "DREAMS" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "DREAMS" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*EL AMEYAL*/}

            {to === "EL AMEYAL" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "EL AMEYAL" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*PUEBLO BONITO PACIFICA*/}

            {to === "PUEBLO BONITO PACIFICA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "PUEBLO BONITO PACIFICA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*PUEBLO BONITO ROSE*/}

            {to === "PUEBLO BONITO ROSE" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "PUEBLO BONITO ROSE" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*PUEBLO BONITO SUNSET BEACH*/}

            {to === "PUEBLO BONITO SUNSET BEACH" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "PUEBLO BONITO SUNSET BEACH" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*PUERTO LOS CABOS RESIDENTIAL AREA*/}

            {to === "PUERTO LOS CABOS RESIDENTIAL AREA" &&
            vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "PUERTO LOS CABOS RESIDENTIAL AREA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*QUINTA DEL SOL*/}

            {to === "QUINTA DEL SOL" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "QUINTA DEL SOL" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*QUERENCIA RESIDENTIAL AREA*/}

            {to === "QUERENCIA RESIDENTIAL AREA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "QUERENCIA RESIDENTIAL AREA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*UIVIRA RESIDENTIAL*/}

            {to === "UIVIRA RESIDENTIAL" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "UIVIRA RESIDENTIAL" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*RITZ ZADUN*/}

            {to === "RITZ ZADUN" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "RITZ ZADUN" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*RIU BAJA (ALL ADULTS RESORT)*/}

            {to === "RIU BAJA (ALL ADULTS RESORT)" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "RIU BAJA (ALL ADULTS RESORT)" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*RIU PALACE*/}

            {to === "RIU PALACE" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "RIU PALACE" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*RIU SANTA FE*/}

            {to === "RIU SANTA FE" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "RIU SANTA FE" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*ROYAL DE CAMERON*/}

            {to === "ROYAL DE CAMERON" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "ROYAL DE CAMERON" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*ROYAL PARK*/}

            {to === "ROYAL PARK" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "ROYAL PARK" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*ROYAL PARK*/}

            {to === "ROYAL SOLARIS" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "ROYAL SOLARIS" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*ROYAL PARK*/}

            {to === "SANDOS FINISTERRA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SANDOS FINISTERRA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SAMPAGUITA*/}

            {to === "SAMPAGUITA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SAMPAGUITA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SECRETS PUERTO LOS CABOS*/}

            {to === "SECRETS PUERTO LOS CABOS" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SECRETS PUERTO LOS CABOS" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SEVEN CROWN*/}

            {to === "SEVEN CROWN" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SEVEN CROWN" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SHERATON HOTEL*/}

            {to === "SHERATON HOTEL" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SHERATON HOTEL" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SIESTA SUITES*/}

            {to === "SIESTA SUITES" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SIESTA SUITES" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SIRENA DEL MAR*/}

            {to === "SIRENA DEL MAR" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SIRENA DEL MAR" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SOLAZ LOS CABOS*/}

            {to === "SOLAZ LOS CABOS" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SOLAZ LOS CABOS" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SOLMAR*/}

            {to === "SOLMAR" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SOLMAR" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SOLEADO BEACH AND SPA*/}

            {to === "SOLEADO BEACH AND SPA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SOLEADO BEACH AND SPA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*SUITES LAS PALMAS*/}

            {to === "SUITES LAS PALMAS" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "SUITES LAS PALMAS" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*TERRASOL*/}

            {to === "TERRASOL" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "TERRASOL" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*TESORO LOS CABOS*/}

            {to === "TESORO LOS CABOS" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "TESORO LOS CABOS" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*THE CAPE THOMPSON*/}

            {to === "THE CAPE THOMPSON" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "THE CAPE THOMPSON" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*THE GRAND MAYAN AT VIDANTA*/}

            {to === "THE GRAND MAYAN AT VIDANTA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "THE GRAND MAYAN AT VIDANTA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*THE WESTIN RESORT AND SPA*/}

            {to === "THE WESTIN RESORT AND SPA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "THE WESTIN RESORT AND SPA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*EL ENCANTO INN*/}

            {to === "EL ENCANTO INN" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "EL ENCANTO INN" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*EL ENCANTO INN*/}

            {to === "EL ENCANTO DE LA LAGUNA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "EL ENCANTO DE LA LAGUNA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*EL GANZO*/}

            {to === "EL GANZO" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "EL GANZO" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*EL ZALATE CONDOS*/}

            {to === "EL ZALATE CONDOS" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "EL ZALATE CONDOS" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {/*ESPERANZA*/}

            {to === "ESPERANZA" && vehicles === "SUBURBAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}

            {to === "ESPERANZA" && vehicles === "VAN" ? (
              <h1 style={{ textAlign: "center" }}>
                ${formData.price} {currency}
              </h1>
            ) : null}
          </div>

          {/* Submit Button */}
          <div className="button-container">
            <Button variant="contained" sx={{backgroundColor: "#000", width: "100%"}}type="submit">BOOK NOW</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormBook;
