import { Link } from "react-router-dom";
import { Image } from "antd";
import Testimonios from "./Testimonios";
import FormBook from "./FormBook";
import {Button} from '@mui/material'

export default function Vehicle() {
    return (
        <div>



{/* <div class="container-fluid bg-dark px-5 d-none d-lg-block">
    <div class="row gx-0">
        <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div class="d-inline-flex align-items-center" style={{height: "45px"}}>
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-twitter fw-normal"></i></a>
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-facebook-f fw-normal"></i></a>
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-linkedin-in fw-normal"></i></a>
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-instagram fw-normal"></i></a>
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href=""><i class="fab fa-youtube fw-normal"></i></a>
            </div>
        </div>
        <div class="col-lg-4 text-center text-lg-end">
            <div class="d-inline-flex align-items-center" style={{height: "45px"}}>
                <a href="#"><small class="me-3 text-light"><i class="fa fa-user me-2"></i>Register</small></a>
                <a href="#"><small class="me-3 text-light"><i class="fa fa-sign-in-alt me-2"></i>Login</small></a>
                <div class="dropdown">
                    <a href="#" class="dropdown-toggle text-light" data-bs-toggle="dropdown"><small><i class="fa fa-home me-2"></i> My Dashboard</small></a>
                    <div class="dropdown-menu rounded">
                        <a href="#" class="dropdown-item"><i class="fas fa-user-alt me-2"></i> My Profile</a>
                        <a href="#" class="dropdown-item"><i class="fas fa-comment-alt me-2"></i> Inbox</a>
                        <a href="#" class="dropdown-item"><i class="fas fa-bell me-2"></i> Notifications</a>
                        <a href="#" class="dropdown-item"><i class="fas fa-cog me-2"></i> Account Settings</a>
                        <a href="#" class="dropdown-item"><i class="fas fa-power-off me-2"></i> Log Out</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}

<div class="container-fluid position-relative p-0">
<nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <Link to="/" class="navbar-brand p-0">
            <h1 class="m-0">
              <img src={require("../images/LOGO.jpg")} alt="Logo" />
              Loreto Transfer
            </h1>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-0">
              <Link to="/" class="nav-item nav-link ">
                Home
              </Link>
              <Link to="/vehicles" class="nav-item nav-link active">
                Vehicles
              </Link>
              <Link to="/about" class="nav-item nav-link ">
                About us
              </Link>
              <Link to="/rates" class="nav-item nav-link ">
                Rates
              </Link>

              <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Activity
                </a>
                <div class="dropdown-menu m-0">
                  <Link to="/activity/water" class="dropdown-item">
                    Water activities
                  </Link>
                  <Link to="/activity" class="dropdown-item">
                    Land activities
                  </Link>
                </div>
              </div>

              <Link to="/contact" class="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Button variant="contained" sx={{ backgroundColor: "#000" }}>
              Book Now
            </Button>
          </div>
        </nav>

</div>


<div class="container-fluid booking py-5">
          <div class="container py-5">
            <div class="row g-5 align-items-center">
              <div class="col-lg-6">
                <h5 class="section-booking-title pe-3">Booking</h5>
                <h1 class="text-white mb-4">Online Booking</h1>
                <p class="text-white mb-4">
                  The best Transportation Service to and from Cabo San Lucas and
                  Cabo San Lucas Airport. Transportation at the best price with
                  Loreto Transfer.
                </p>
              </div>
              <div class="col-lg-6">
              
                <FormBook/>

              </div>
            </div>


            
          </div>
        </div>


    <div class="container-fluid gallery py-2 my-5">
      

        <div class="mx-auto text-center mb-5" style={{maxWidth: "900px;"}}>
        <h5 class="section-title px-3">Vehicles</h5>
        <h1 class="mb-4">Ours vehicles</h1>
       
    </div>
        <div class="container-fluid blog ">
          <div class="container py-2">
            <div class="row g-4 justify-content-center">
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/bg-camioneta.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/auto.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/auto-mobile.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img ">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/scalade.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/suburban.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/trafi-replace.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/trafi1.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/trafi.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/new-trafi.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 <Testimonios/>




<div class="container-fluid footer py-5">
    <div class="container py-5">
        <div class="row g-5">
            <div class="col-md-6 col-lg-6 col-xl-3">
                <div class="footer-item d-flex flex-column">
                    <h4 class="mb-4 text-white">Get In Touch</h4>
                    <a href=""><i class="fas fa-home me-2"></i> 123 Street, New York, USA</a>
                    <a href=""><i class="fas fa-envelope me-2"></i> info@example.com</a>
                    <a href=""><i class="fas fa-phone me-2"></i> +012 345 67890</a>
                    <a href="" class="mb-3"><i class="fas fa-print me-2"></i> +012 345 67890</a>
                    <div class="d-flex align-items-center">
                        <i class="fas fa-share fa-2x text-white me-2"></i>
                        <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                        <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                        <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                        <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
                <div class="footer-item d-flex flex-column">
                    <h4 class="mb-4 text-white">Company</h4>
                    <a href=""><i class="fas fa-angle-right me-2"></i> About</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Careers</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Blog</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Press</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Gift Cards</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Magazine</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
                <div class="footer-item d-flex flex-column">
                    <h4 class="mb-4 text-white">Support</h4>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Contact</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Legal Notice</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Privacy Policy</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Terms and Conditions</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Sitemap</a>
                    <a href=""><i class="fas fa-angle-right me-2"></i> Cookie policy</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
                <div class="footer-item">
                    <div class="row gy-3 gx-2 mb-4">
                        <div class="col-xl-6">
                            <form>
                                <div class="form-floating">
                                    <select class="form-select bg-dark border" id="select1">
                                        <option value="1">Arabic</option>
                                        <option value="2">German</option>
                                        <option value="3">Greek</option>
                                        <option value="3">New York</option>
                                    </select>
                                    <label for="select1">English</label>
                                </div>
                            </form>
                        </div>
                        <div class="col-xl-6">
                            <form>
                                <div class="form-floating">
                                    <select class="form-select bg-dark border" id="select1">
                                        <option value="1">USD</option>
                                        <option value="2">EUR</option>
                                        <option value="3">INR</option>
                                        <option value="3">GBP</option>
                                    </select>
                                    <label for="select1">$</label>
                                </div>
                            </form>
                        </div>
                    </div>
                    <h4 class="text-white mb-3">Payments</h4>
                    <div class="footer-bank-card">
                        <a href="#" class="text-white me-2"><i class="fab fa-cc-amex fa-2x"></i></a>
                        <a href="#" class="text-white me-2"><i class="fab fa-cc-visa fa-2x"></i></a>
                        <a href="#" class="text-white me-2"><i class="fas fa-credit-card fa-2x"></i></a>
                        <a href="#" class="text-white me-2"><i class="fab fa-cc-mastercard fa-2x"></i></a>
                        <a href="#" class="text-white me-2"><i class="fab fa-cc-paypal fa-2x"></i></a>
                        <a href="#" class="text-white"><i class="fab fa-cc-discover fa-2x"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid copyright text-body py-4">
    <div class="container">
        <div class="row g-4 align-items-center">
            <div class="col-md-6 text-center text-md-end mb-md-0">
                <i class="fas fa-copyright me-2"></i><a class="text-white" href="#">Your Site Name</a>, All right reserved.
            </div>
            <div class="col-md-6 text-center text-md-start">
                Designed By <a class="text-white" href="https://htmlcodex.com">HTML Codex</a>
            </div>
        </div>
    </div>
</div>

<a href="#" class="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i class="fa fa-arrow-up"></i></a>   



</div>
    )
}