import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Image } from "antd";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: "100%"}}>
      <Box
        sx={{
          display: 'flex',
          gap: "10px",
          padding: '10px',
          
        }}
      >
        {/* Botones personalizados para las pestañas */}
        <Button
          onClick={() => handleChange(0)}
          sx={{
            border: "1px solid #000",
            color: value === 0 ? 'white' : '#000',
            backgroundColor: value === 0 ? '#000' : 'white',
            
            '&:hover': { backgroundColor: value === 0 ? '#000' : '#000', color: value === 0 ? 'white' : 'white',  },
          }}
        >
        ALL
        </Button>
        <Button
          onClick={() => handleChange(1)}
          sx={{
            border: "1px solid #000",

            color: value === 1 ? 'white' : '#000',
            backgroundColor: value === 1 ? '#000' : 'white',
            
            '&:hover': { backgroundColor: value === 1 ? '#000' : '#000', color: value === 1 ? 'white' : 'white',  },
          }}
        >
      SUBURBAN
        </Button>
        <Button
          onClick={() => handleChange(2)}
          sx={{
            border: "1px solid #000",

            color: value === 2 ? 'white' : '#000',
            backgroundColor: value === 2 ? '#000' : 'white',
            
            '&:hover': { backgroundColor: value === 2 ? '#000' : '#000', color: value === 2 ? 'white' : 'white',  },
          }}
        >
         ESCALADE
        </Button>

        <Button
          onClick={() => handleChange(3)}
          sx={{
            border: "1px solid #000",

            color: value === 3 ? 'white' : '#000',
            backgroundColor: value === 3 ? '#000' : 'white',
            
            '&:hover': { backgroundColor: value === 3 ? '#000' : '#000', color: value === 3 ? 'white' : 'white',  },
          }}
        >
         HIACE/VAN
        </Button>

        <Button
          onClick={() => handleChange(4)}
          sx={{
            border: "1px solid #000",

            color: value === 4 ? 'white' : '#000',
            backgroundColor: value === 4 ? '#000' : 'white',
            
            '&:hover': { backgroundColor: value === 4 ? '#000' : '#000', color: value === 4 ? 'white' : 'white',  },
          }}
        >
         MINI BUS
        </Button>
      </Box>

      {/* Panel de contenido */}
      <TabPanel value={value} index={0}>
      <div class="container-fluid blog ">
        <div class="container py-5">

          <div class="row g-4 justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Cabo-Escape-Sunset.jpg?w=400&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/423a8c87-0834-41ef-988f-b9882e8484a31.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/6.2.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/0-CaboEscape-food41.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/0-CaboEscape-sunset581.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Actual_sunset_fajitas1-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/6.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Actual_sunset_fajitas15.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Cabo-Escape-Sunset.jpg?w=400&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails12.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails16.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Tu-Enamorado-Yacht.-Los-Cabos.-Julieta-Amezcua-Photography.-281-of-291.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Tu-Enamorado-Yacht.-Los-Cabos.-Julieta-Amezcua-Photography.-135-of-291.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails21.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails7.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails12.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

           
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Yo-Ho-Ho-Sunset-Cruise-image-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Yo-Ho-Ho-Sunset-Cruise-image-2.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Actual_yoho_show20.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Yo-Ho-Ho-Sunset-Cruise-image-1.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

        
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img ">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2020/03/dinner-cruise.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2021/03/18-CaboWave-Decoracion-50-scaled.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2021/03/1-2.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/barco-Cabo-WaveDSC07844.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2020/03/dinner-cruise.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

            
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/0-Buccaneer-barco1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/selfie-with-the-crew.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Adventure-at-Dusk-Sunset-Cruise-image-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Actual_yoho_show21-scaled.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/0-Buccaneer-barco1.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

         
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Snorkel-Fun-Lunch-image-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Copy-of-0-CaboEscape-barco51.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/18-CaboPirates-Snorkel30.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/barco-cabo-wave-31-e1544224281159.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/MG_8288.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/MG_8214.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Snorkel-Fun-Lunch-image-1.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

           
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/3..jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/18-CaboPirates-Snorkel30.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/photo.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Copy-of-lunch-pirata.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Cabo-Wave-Morning-Snorkel-image-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/sunset-fajitas-_52.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/3..jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

           
              </div>
            </div>
            </div>
            </div>
            </div>

      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
