import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Rating, Avatar, Stack } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Datos de testimonios
const testimonios = [
  {
    nombre: "Juan Pérez",
    avatar: "https://i.pravatar.cc/150?u=juan",
    mensaje: "¡Este servicio es increíble! Realmente lo recomendaría a cualquiera.",
    estrellas: 4.5,
  },
  {
    nombre: "María García",
    avatar: "https://i.pravatar.cc/150?u=maria",
    mensaje: "Una experiencia excelente, el soporte al cliente es fantástico.",
    estrellas: 5,
  },
  {
    nombre: "Carlos López",
    avatar: "https://i.pravatar.cc/150?u=carlos",
    mensaje: "La calidad es buena, pero puede mejorar en ciertos aspectos.",
    estrellas: 3.5,
  },
  {
    nombre: "Ana Morales",
    avatar: "https://i.pravatar.cc/150?u=ana",
    mensaje: "¡Sin duda volveré a utilizar este servicio!",
    estrellas: 4,
  },
];

const Testimonios = () => {
  // Configuración del carrusel
  const settings = {
    dots: true, // Puntos de navegación
    infinite: true, // Loop infinito
    speed: 500, // Velocidad de la transición
    autoplay: true, // Reproducción automática
    autoplaySpeed: 2000, // Tiempo entre deslizamientos
    slidesToShow: 3, // Testimonios mostrados por defecto
    slidesToScroll: 1, // Testimonios deslizados por movimiento
    responsive: [
      {
        breakpoint: 1024, // Tamaño máximo para tablets
        settings: {
          slidesToShow: 2, // Mostrar 2 testimonios en tabletas
        },
      },
      {
        breakpoint: 600, // Tamaño máximo para dispositivos móviles
        settings: {
          slidesToShow: 1, // Mostrar 1 testimonio en móviles
        },
      },
    ],
  };

  return (
    <Box sx={{ maxWidth: 1000, margin: '0 auto', padding: 4 }}>
          <div class="mx-auto text-center mb-5" style={{maxWidth: "900px"}}>
                <h1 class="mb-4">Reviews</h1>
               
            </div>
      <Slider {...settings}>
        {testimonios.map((testimonio, index) => (
          <Box
            key={index}
            sx={{
              border: '1px solid #ccc',
              borderRadius: 2,
              padding: 3,
              boxShadow: 2,
              backgroundColor: '#fff',
              margin: '0 10px', // Margen horizontal entre tarjetas
              height: 220, // Altura fija para las tarjetas
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                src={testimonio.avatar}
                alt={testimonio.nombre}
                sx={{ width: 56, height: 56 }}
              />
              <Box>
                <Typography variant="h6">{testimonio.nombre}</Typography>
                <Rating
                  value={testimonio.estrellas}
                  precision={0.5}
                  readOnly
                />
              </Box>
            </Stack>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              {testimonio.mensaje}
            </Typography>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Testimonios;
