import {BrowserRouter as Router, Routes, Route, } from 'react-router-dom'
import Home from './components/Home';
import Vehicle from './components/Vehicle';
import About from './components/About';
import ActivityWater from './components/ActivityWater';
import Activity from './components/Activity';
import Contact from './components/Contact';
import Test from './components/Test';


function App() {
  return (
    <div >
      <Router>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/vehicles" element={<Vehicle/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/activity/water" element={<ActivityWater/>} />
        <Route path="/activity" element={<Activity/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/test" element={<Test/>} />






        




        </Routes>
      </Router>
    
    </div>
  );
}

export default App;
