import React from "react";
import { Link } from "react-router-dom";
import Testimonios from "./Testimonios";
import { Image } from "antd";
import { Button } from "@mui/material";
import FormBook from './FormBook'
export default function Home() {
  return (
    <div>
{/*       <div class="container-fluid bg-dark px-5 d-none d-lg-block">
        <div class="row gx-0">
          <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              class="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href=""
              >
                <i class="fab fa-twitter fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href=""
              >
                <i class="fab fa-facebook-f fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href=""
              >
                <i class="fab fa-linkedin-in fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href=""
              >
                <i class="fab fa-instagram fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
                href=""
              >
                <i class="fab fa-youtube fw-normal"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-4 text-center text-lg-end">
            <div
              class="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <a href="#">
                <small class="me-3 text-light">
                  <i class="fa fa-user me-2"></i>Register
                </small>
              </a>
              <a href="#">
                <small class="me-3 text-light">
                  <i class="fa fa-sign-in-alt me-2"></i>Login
                </small>
              </a>
              <div class="dropdown">
                <a
                  href="#"
                  class="dropdown-toggle text-light"
                  data-bs-toggle="dropdown"
                >
                  <small>
                    <i class="fa fa-home me-2"></i> My Dashboard
                  </small>
                </a>
                <div class="dropdown-menu rounded">
                  <a href="#" class="dropdown-item">
                    <i class="fas fa-user-alt me-2"></i> My Profile
                  </a>
                  <a href="#" class="dropdown-item">
                    <i class="fas fa-comment-alt me-2"></i> Inbox
                  </a>
                  <a href="#" class="dropdown-item">
                    <i class="fas fa-bell me-2"></i> Notifications
                  </a>
                  <a href="#" class="dropdown-item">
                    <i class="fas fa-cog me-2"></i> Account Settings
                  </a>
                  <a href="#" class="dropdown-item">
                    <i class="fas fa-power-off me-2"></i> Log Out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <Link to="/" class="navbar-brand p-0">
            <h1 class="m-0">
              <img src={require("../images/LOGO.jpg")} alt="Logo" />
              Loreto Transfer
            </h1>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-0">
              <Link to="/" class="nav-item nav-link active">
                Home
              </Link>
              <Link to="/vehicles" class="nav-item nav-link ">
                Vehicles
              </Link>
              <Link to="/about" class="nav-item nav-link ">
                About us
              </Link>
              <Link to="/rates" class="nav-item nav-link ">
                Rates
              </Link>

              <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Activity
                </a>
                <div class="dropdown-menu m-0">
                  <Link to="/activity/water" class="dropdown-item">
                    Water activities
                  </Link>
                  <Link to="/activity" class="dropdown-item">
                    Land activities
                  </Link>
                </div>
              </div>

              <Link to="/contact" class="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Button variant="contained" sx={{ backgroundColor: "#000" }}>
              Book Now
            </Button>
          </div>
        </nav>

        <div class="container-fluid booking py-5">
          <div class="container py-5">
            <div class="row g-5 align-items-center">
              <div class="col-lg-6">
                <h5 class="section-booking-title pe-3">Booking</h5>
                <h1 class="text-white mb-4">Online Booking</h1>
                <p class="text-white mb-4">
                  The best Transportation Service to and from Cabo San Lucas and
                  Cabo San Lucas Airport. Transportation at the best price with
                  Loreto Transfer.
                </p>
              </div>
              <div class="col-lg-6">
              
                <FormBook/>

              </div>
            </div>


            
          </div>
        </div>
      </div>

      <div class="container-fluid about py-5">
  <div class="container py-5">
    <div class="row g-5 align-items-center">
      <div class="col-lg-5">
        <div class="h-100">
          <img
            src={require("../images/contact-bg.jpg")}
            class="img-fluid w-100 h-100"
            alt=""
          />
        </div>
      </div>
      <div
        class="col-lg-7"
   
      >
        <h5 class="section-about-title pe-3">About Us</h5>
        <h1 class="mb-4">
          Welcome to <span class="text-dark">Loreto Transfer</span>
        </h1>
        <p class="mb-4">
          At <strong>Loreto Transfer</strong>, we believe that your journey is as important as your destination. We’re passionate about making your travel experience unforgettable by offering exclusive transportation services to and from Cabo San Lucas and its beautiful surroundings.
        </p>
        <p class="mb-4">
          Whether you're visiting for the first time or returning for another adventure, we are committed to providing you with the highest level of comfort, convenience, and affordability. Our carefully curated fleet of vehicles and experienced chauffeurs ensure that your travel is seamless, safe, and enjoyable from the moment you arrive.
        </p>
        <div class="row gy-2 gx-4 mb-4">
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>First Class Flights</strong> – We ensure your journey starts in style with luxurious, first-class flights to Cabo San Lucas.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>Handpicked Hotels</strong> – Relax in the finest hotels, selected for their comfort, luxury, and proximity to key destinations.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>5 Star Accommodations</strong> – Stay in exceptional, 5-star accommodations for the ultimate relaxation after your journey.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>Latest Model Vehicles</strong> – Travel in the newest, most luxurious vehicles for a comfortable and smooth ride throughout your trip.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>150 Premium City Tours</strong> – Explore the beauty of Cabo with curated, premium city tours that ensure you don’t miss any of the hidden gems.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>24/7 Service</strong> – Our dedicated team is always available to assist you at any hour, ensuring a seamless travel experience from start to finish.
    </p>
  </div>
</div>

      </div>
    </div>
  </div>
</div>


      <div class="container-fluid bg-light service py-5">
        <div class="container py-5">
            
          <div class="row g-4">
            <div class="col-lg-6">
              <div class="row g-4">
                <div class="col-12">
                  <div class="service-content-inner d-flex align-items-center bg-white border border-dark rounded pe-0">
                    <div class="service-content text-justify p-4">
                      <div class="service-icon p-4">
                        <img
                          src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2023/10/easy_online_booking.jpg/W=450,dpr=2,fit=cover"
                          class="img-service"
                        />
                      </div>
                      <h5 class="mb-4">Uninterrupted Convenience</h5>
                      <p class="mb-0">
                        Booking Transfeero's hourly service means you can have a
                        chauffeur ready and waiting to take you to your next
                        destination, regardless of how many stops you need to
                        make. No more waiting around for rides or planning your
                        day around the availability of transport options. Our
                        chauffeur stays with you, providing continuous,
                        uninterrupted service.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="service-content-inner d-flex align-items-center  bg-white border border-dark rounded pe-0">
                    <div class="service-content text-justify p-4">
                      <div class="service-icon p-4">
                        <img
                          src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2023/10/flight_traking-1.jpg/W=450,dpr=2,fit=cover"
                          class="img-service"
                        />
                      </div>
                      <h5 class="mb-4">A Tailored Itinerary</h5>
                      <p class="mb-0">
                        Our clients value the ability to craft their own
                        itinerary without being bound to a fixed schedule. As
                        you book our Hourly Chauffeur Service, you can set the
                        pace of the day. Decide when to depart, where to go, and
                        how long to stay. Whether it's impromptu detours to
                        hidden gems or extended stays at a business engagement,
                        the flexibility is yours.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="row g-4">
                <div class="col-12">
                  <div class="service-content-inner d-flex align-items-center  bg-white border border-dark rounded  pe-0">
                    <div class="service-content text-justify p-4">
                      <div class="service-icon p-4">
                        <img
                          src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2023/10/clear_price-1.jpg/W=450,dpr=2,fit=cover"
                          class="img-service"
                        />
                      </div>
                      <h5 class="mb-4">Cost-Effectiveness</h5>
                      <p class="mb-0">
                        With our transparent and competitive pricing, you'll
                        find our Hourly Chauffeur Service to be a cost-effective
                        option. There are no hidden charges, and you benefit
                        from the advantage of having a car on standby without
                        the meter running endlessly. You pay for the time you
                        book, giving you control over your travel budget.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="service-content-inner d-flex align-items-center bg-white border border-dark rounded  pe-0">
                    <div class="service-content text-justify p-4">
                      <div class="service-icon p-4">
                        <img
                          src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2023/10/pro_driver.jpg/W=450,dpr=2,fit=cover"
                          class="img-service"
                        />
                      </div>
                      <h5 class="mb-4">Top-Notch Professional Chauffeurs</h5>
                      <p class="mb-0">
                        Our professional chauffeurs are not only skilled drivers
                        but also understand the importance of customer service.
                        They are local experts and can provide valuable insights
                        about the area, recommend places to visit, and assist
                        with any particular requirements you may have throughout
                        your trip.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    {/*   <div class="container-fluid destination py-5">
        <div class="container py-5">
          <div class="mx-auto text-center mb-5" style={{ maxWidth: "900px" }}>
            <h1 class="mb-0">Popular Destination</h1>
          </div>
          <div class="tab-class text-center">
            <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
              <li class="nav-item">
                <a
                  class="d-flex mx-3 py-2 border border-dark bg-light rounded-pill active"
                  data-bs-toggle="pill"
                  href="#tab-1"
                >
                  <span class="text-dark" style={{ width: "150px" }}>
                    All
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="d-flex py-2 mx-3 border border-dark bg-light rounded-pill"
                  data-bs-toggle="pill"
                  href="#tab-2"
                >
                  <span class="text-dark" style={{ width: "150px" }}>
                    Cabo San Lucas
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="d-flex mx-3 py-2 border border-dark bg-light rounded-pill"
                  data-bs-toggle="pill"
                  href="#tab-3"
                >
                  <span class="text-dark" style={{ width: "150px" }}>
                    San Jose del Cabo
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="d-flex mx-3 py-2 border border-dark bg-light rounded-pill"
                  data-bs-toggle="pill"
                  href="#tab-4"
                >
                  <span class="text-dark" style={{ width: "150px" }}>
                    Puerto Los Cabos
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="d-flex mx-3 py-2 border border-dark bg-light rounded-pill"
                  data-bs-toggle="pill"
                  href="#tab-5"
                >
                  <span class="text-dark" style={{ width: "150px" }}>
                    Encanto la Laguna
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="d-flex mx-3 py-2 border border-dark bg-light rounded-pill"
                  data-bs-toggle="pill"
                  href="#tab-6"
                >
                  <span class="text-dark" style={{ width: "150px" }}>
                    Pacific{" "}
                  </span>
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="tab-1" class="tab-pane fade show p-0 active">
                <div class="row g-4">
                  <div class="col-xl-8">
                    <div class="row g-4">
                      <div class="col-lg-6">
                        <div class="destination-img">
                          <img
                            class="img-fluid rounded w-100"
                            src="img/destination-1.jpg"
                            alt=""
                          />
                          <div class="destination-overlay p-4">
                            <h4 class="text-white mb-2 mt-3">New York City</h4>
                          </div>
                          <div class="search-icon">
                            <a
                              href="img/destination-1.jpg"
                              data-lightbox="destination-1"
                            >
                              <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="destination-img">
                          <img
                            class="img-fluid rounded w-100"
                            src="img/destination-2.jpg"
                            alt=""
                          />
                          <div class="destination-overlay p-4">
                            <h4 class="text-white mb-2 mt-3">Las vegas</h4>
                          </div>
                          <div class="search-icon">
                            <a
                              href="img/destination-2.jpg"
                              data-lightbox="destination-2"
                            >
                              <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="destination-img">
                          <img
                            class="img-fluid rounded w-100"
                            src="img/destination-7.jpg"
                            alt=""
                          />
                          <div class="destination-overlay p-4">
                            <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                          </div>
                          <div class="search-icon">
                            <a
                              href="img/destination-7.jpg"
                              data-lightbox="destination-7"
                            >
                              <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="destination-img">
                          <img
                            class="img-fluid rounded w-100"
                            src="img/destination-8.jpg"
                            alt=""
                          />
                          <div class="destination-overlay p-4">
                            <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                          </div>
                          <div class="search-icon">
                            <a
                              href="img/destination-8.jpg"
                              data-lightbox="destination-8"
                            >
                              <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="destination-img h-100">
                      <img
                        class="img-fluid rounded w-100 h-100"
                        src="img/destination-9.jpg"
                        style={{ objectFit: "cover;", minHeight: "300px;" }}
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-9.jpg"
                          data-lightbox="destination-4"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-4.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-4.jpg"
                          data-lightbox="destination-4"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-5.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-5.jpg"
                          data-lightbox="destination-5"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-6.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-6.jpg"
                          data-lightbox="destination-6"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab-2" class="tab-pane fade show p-0">
                <div class="row g-4">
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-5.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-5.jpg"
                          data-lightbox="destination-5"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-6.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-6.jpg"
                          data-lightbox="destination-6"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab-3" class="tab-pane fade show p-0">
                <div class="row g-4">
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-5.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-5.jpg"
                          data-lightbox="destination-5"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-6.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-6.jpg"
                          data-lightbox="destination-6"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab-4" class="tab-pane fade show p-0">
                <div class="row g-4">
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-5.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-5.jpg"
                          data-lightbox="destination-5"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-6.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-6.jpg"
                          data-lightbox="destination-6"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab-5" class="tab-pane fade show p-0">
                <div class="row g-4">
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-5.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-5.jpg"
                          data-lightbox="destination-5"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-6.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-6.jpg"
                          data-lightbox="destination-6"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab-6" class="tab-pane fade show p-0">
                <div class="row g-4">
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-5.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-5.jpg"
                          data-lightbox="destination-5"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/destination-6.jpg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">San francisco</h4>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/destination-6.jpg"
                          data-lightbox="destination-6"
                        >
                          <i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="container-fluid blog ">
        <div class="container py-5">
          <div class="mx-auto text-center mb-5" style={{ maxWidth: "900px;" }}>
            <h1 class="mb-4">Land Activities</h1>
            <p class="mb-0">
              Exploring the wonders of land activities opens a world of
              adventure and discovery. From hiking through lush forests to
              experiencing the thrill of off-road excursions, the earth offers
              endless opportunities to connect with nature. These activities not
              only promote physical well-being but also provide a chance to
              appreciate the beauty of our planet. Whether you're trekking
              mountain trails or enjoying a peaceful countryside walk, every
              step unveils a new story waiting to be discovered.
            </p>
          </div>
          <div class="row g-4 justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="blog-item">
                <div class="blog-img">
                  <div class="blog-img-inner">
                    <img
                      class="img-fluid w-100 rounded-top"
                      src="img/blog-1.jpg"
                      alt="Image"
                    />
                    <div class="blog-icon"></div>
                  </div>
                </div>
                <div class="blog-content border border-top-0 rounded-bottom p-4">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Adventures Trip
                  </a>
                  <p class="my-3">
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit
                    diam amet diam eos
                  </p>
                  <Button variant="contained" sx={{ backgroundColor: "#000" }}>
                    Read More
                  </Button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="blog-item">
                <div class="blog-img">
                  <div class="blog-img-inner">
                    <img
                      class="img-fluid w-100 rounded-top"
                      src="img/blog-2.jpg"
                      alt="Image"
                    />
                    <div class="blog-icon"></div>
                  </div>
                </div>
                <div class="blog-content border border-top-0 rounded-bottom p-4">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Adventures Trip
                  </a>
                  <p class="my-3">
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit
                    diam amet diam eos
                  </p>
                  <Button variant="contained" sx={{ backgroundColor: "#000" }}>
                    Read More
                  </Button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="blog-item">
                <div class="blog-img">
                  <div class="blog-img-inner">
                    <img
                      class="img-fluid w-100 rounded-top"
                      src="img/blog-3.jpg"
                      alt="Image"
                    />
                    <div class="blog-icon"></div>
                  </div>
                </div>
                <div class="blog-content border border-top-0 rounded-bottom p-4">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Adventures Trip
                  </a>
                  <p class="my-3">
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit
                    diam amet diam eos
                  </p>
                  <Button variant="contained" sx={{ backgroundColor: "#000" }}>
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid blog ">
        <div class="container py-5">
          <div class="mx-auto text-center mb-5" style={{ maxWidth: "900px;" }}>
            <h1 class="mb-4">Water Activities</h1>
            <p class="mb-0">
              Diving into the world of water activities unlocks endless
              excitement and tranquility. From kayaking through serene rivers to
              surfing powerful ocean waves, water adventures offer a unique way
              to connect with nature. These activities provide opportunities to
              stay active, unwind, and explore breathtaking aquatic landscapes.
              Whether you're snorkeling in crystal-clear waters or enjoying a
              peaceful boat ride, each moment on the water reveals a new
              perspective of our planet's beauty and vitality.
            </p>
          </div>
          <div class="row g-4 justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Cabo-Escape-Sunset.jpg?w=400&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/423a8c87-0834-41ef-988f-b9882e8484a31.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/6.2.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/0-CaboEscape-food41.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/0-CaboEscape-sunset581.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Actual_sunset_fajitas1-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/6.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Actual_sunset_fajitas15.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Cabo-Escape-Sunset.jpg?w=400&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

                <div class="blog-content border border-top-0 rounded-bottom p-4 d-flex flex-column">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Sunset Fajita Dinner & Entertainment Cruise
                  </a>
                  <p class="my-2">
                    Enjoy an exciting sightseeing cruise of the world-famous
                    Land’s End, a spectacular sunset, and a delicious dinner
                    prepared right on deck.
                  </p>
                  <div className="mt-auto d-flex justify-content-between align-items-center">
  <Button
    variant="contained"
    sx={{ backgroundColor: "#000",  justifyContent: "center", display: "flex"}}
  >
    Contact us
  </Button>
  <div>

  <strong style={{textAlign: "end"}}>$500</strong>
  </div>

</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails12.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails16.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Tu-Enamorado-Yacht.-Los-Cabos.-Julieta-Amezcua-Photography.-281-of-291.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Tu-Enamorado-Yacht.-Los-Cabos.-Julieta-Amezcua-Photography.-135-of-291.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails21.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails7.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/Actual_sunset_sails12.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

                <div class="blog-content border border-top-0 rounded-bottom p-4 d-flex flex-column">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Sunset Yacht & Dinner Cruise- Tu Enamorado
                  </a>
                  <p class="my-2">
                    Enjoy an Adults only exciting sightseeing sail of the
                    world-famous Land’s End, a spectacular sunset, with a high
                    end dinner and premium open bar on our beautiful yacht!
                  </p>
                  <div className="mt-auto d-flex justify-content-between align-items-center">
  <Button
    variant="contained"
    sx={{ backgroundColor: "#000",  justifyContent: "center", display: "flex"}}
  >
    Contact us
  </Button>
  <div>

  <strong style={{textAlign: "end"}}>$500</strong>
  </div>

</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Yo-Ho-Ho-Sunset-Cruise-image-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Yo-Ho-Ho-Sunset-Cruise-image-2.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Actual_yoho_show20.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Yo-Ho-Ho-Sunset-Cruise-image-1.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

                <div class="blog-content border border-top-0 rounded-bottom p-4 d-flex flex-column">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Yo Ho Ho Sunset Dinner & Pirate Show Cruise
                  </a>
                  <p class="my-2">
                    Hop aboard our wooden pirate ship and take part in our
                    hands-on pirate adventure.
                  </p>
                  <div className="mt-auto d-flex justify-content-between align-items-center">
  <Button
    variant="contained"
    sx={{ backgroundColor: "#000",  justifyContent: "center", display: "flex"}}
  >
    Contact us
  </Button>
  <div>

  <strong style={{textAlign: "end"}}>$500</strong>
  </div>

</div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img ">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2020/03/dinner-cruise.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2021/03/18-CaboWave-Decoracion-50-scaled.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2021/03/1-2.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/barco-Cabo-WaveDSC07844.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2020/03/dinner-cruise.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

                <div class="blog-content border border-top-0 rounded-bottom p-4 d-flex flex-column">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Luxury Sunset Dinner – Cabo Wave
                  </a>
                  <p class="my-2">
                    Enjoy an exciting sightseeing cruise of the world-famous
                    Land’s End, a spectacular sunset, with a great dinner and
                    open bar. DJ & MC keep you entretained while visiting the
                    World Famous Land’s End and Cruise Los Cabos Bay.
                  </p>
                  <div className="mt-auto d-flex justify-content-between align-items-center">
  <Button
    variant="contained"
    sx={{ backgroundColor: "#000",  justifyContent: "center", display: "flex"}}
  >
    Contact us
  </Button>
  <div>

  <strong style={{textAlign: "end"}}>$500</strong>
  </div>

</div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/0-Buccaneer-barco1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/selfie-with-the-crew.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Adventure-at-Dusk-Sunset-Cruise-image-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Actual_yoho_show21-scaled.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/0-Buccaneer-barco1.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

                <div class="blog-content border border-top-0 rounded-bottom p-4 d-flex flex-column">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Yo Ho Show (Buccaneer Queen)
                  </a>
                  <p class="my-2">
                    See the most beautiful sights in Cabo San Lucas illuminated
                    by the magnificent sunset.
                  </p>
                  <div className="mt-auto d-flex justify-content-between align-items-center">
  <Button
    variant="contained"
    sx={{ backgroundColor: "#000",  justifyContent: "center", display: "flex"}}
  >
    Contact us
  </Button>
  <div>

  <strong style={{textAlign: "end"}}>$500</strong>
  </div>

</div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Snorkel-Fun-Lunch-image-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Copy-of-0-CaboEscape-barco51.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/18-CaboPirates-Snorkel30.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/barco-cabo-wave-31-e1544224281159.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/12/MG_8288.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/MG_8214.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Snorkel-Fun-Lunch-image-1.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

                <div class="blog-content border border-top-0 rounded-bottom p-4 d-flex flex-column">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Snorkel Fun with Lunch
                  </a>
                  <p class="my-2">
                    Visit Cabo’s world-famous Arch, explore the incredible
                    marine life of the Sea of Cortez, and enjoy a delicious
                    lunch prepared on board.
                  </p>
                  <div className="mt-auto d-flex justify-content-between align-items-center">
  <Button
    variant="contained"
    sx={{ backgroundColor: "#000",  justifyContent: "center", display: "flex"}}
  >
    Contact us
  </Button>
  <div>

  <strong style={{textAlign: "end"}}>$500</strong>
  </div>

</div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/3..jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/18-CaboPirates-Snorkel30.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/photo.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Copy-of-lunch-pirata.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Cabo-Wave-Morning-Snorkel-image-1.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/sunset-fajitas-_52.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/3..jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

                <div class="blog-content border border-top-0 rounded-bottom p-4 d-flex flex-column">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Treasure Hunt Guided Snorkel & Lunch Pirate Tour
                  </a>
                  <p class="my-2">
                    Cruise back in time aboard our pirate sailboat, learn about
                    Cabo’s history, and spend quality time with tropical fish.
                  </p>
                  <div className="mt-auto d-flex justify-content-between align-items-center">
  <Button
    variant="contained"
    sx={{ backgroundColor: "#000",  justifyContent: "center", display: "flex"}}
  >
    Contact us
  </Button>
  <div>

  <strong style={{textAlign: "end"}}>$500</strong>
  </div>

</div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item card-equal-height">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2019/05/348406c9-9d3c-4b0e-a128-43ef220229a2.jpg?resize=360%2C240&zoom=2",
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2019/05/SUP1-002.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2019/05/348406c9-9d3c-4b0e-a128-43ef220229a2.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>

                <div class="blog-content border border-top-0 rounded-bottom p-4 d-flex flex-column">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Land´s End Paddle Boarding Tour
                  </a>
                  <p class="my-2">
                    Visit Cabo’s world-famous Arch at Land’s End with our
                    certified paddle board guide and enjoy the sun rising on the
                    horizon all while paddle boarding on the Sea of Cortez.
                    Afterwards have a hearty breakfast at our restaurant La
                    Terminal on the marina.
                  </p>
                  <div className="mt-auto d-flex justify-content-between align-items-center">
  <Button
    variant="contained"
    sx={{ backgroundColor: "#000",  justifyContent: "center", display: "flex"}}
  >
    Contact us
  </Button>
  <div>

  <strong style={{textAlign: "end"}}>$500</strong>
  </div>

</div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 ">
              <div class="blog-item card-equal-height ">
                <div class="blog-img">
                  <div>
                    <Image.PreviewGroup
                      items={[
                        "https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Whale-Watching-Breakfast-image-1.jpg?resize=360%2C240&zoom=2",
                      ]}
                    >
                      <Image
                        width={"100%"}
                        height={250}
                        src="https://www.wildcabotours.com/wp-content/uploads/sites/1903/2018/10/Whale-Watching-Breakfast-image-1.jpg?resize=360%2C240&zoom=2"
                      />
                    </Image.PreviewGroup>
                    <div class="blog-icon"></div>
                  </div>
                </div>
                <div class="blog-content border border-top-0 rounded-bottom p-4 d-flex flex-column">
                  <p class="mb-3">Posted By: Royal Hamblin </p>
                  <a href="#" class="h4">
                    Whale Watching with Breakfast
                  </a>
                  <p class="my-2">
                    Get up close to Cabo’s whales and enjoy a fresh breakfast!
                  </p>

                  <div className="mt-auto d-flex justify-content-between align-items-center">
  <Button
    variant="contained"
    sx={{ backgroundColor: "#000",  justifyContent: "center", display: "flex"}}
  >
    Contact us
  </Button>
  <div>

  <strong style={{textAlign: "end"}}>$500</strong>
  </div>

</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid gallery py-2 my-5">
        <div class="mx-auto text-center mb-5" style={{ maxWidth: "900px" }}>
          <h1 class="mb-4">Ours vehicles</h1>
        </div>
        <div class="container-fluid blog ">
          <div class="container py-2">
            <div class="row g-4 justify-content-center">
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/bg-camioneta.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/auto.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/auto-mobile.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img ">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/scalade.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/suburban.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/trafi-replace.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/trafi1.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/trafi.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item card-equal-height">
                  <div class="blog-img">
                    <div>
                      <Image
                        width={"100%"}
                        height={250}
                        src={require("../images/new-trafi.jpg")}
                      />
                      <div class="blog-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  

      <Testimonios />

      <div class="container-fluid footer py-5">
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="footer-item d-flex flex-column">
                <h4 class="mb-4 text-white">Get In Touch</h4>
                <a href="">
                  <i class="fas fa-home me-2"></i> 123 Street, New York, USA
                </a>
                <a href="">
                  <i class="fas fa-envelope me-2"></i> info@example.com
                </a>
                <a href="">
                  <i class="fas fa-phone me-2"></i> +012 345 67890
                </a>
                <a href="" class="mb-3">
                  <i class="fas fa-print me-2"></i> +012 345 67890
                </a>
                <div class="d-flex align-items-center">
                  <i class="fas fa-share fa-2x text-white me-2"></i>
                  <a
                    class="btn-square btn btn-primary rounded-circle mx-1"
                    href=""
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    class="btn-square btn btn-primary rounded-circle mx-1"
                    href=""
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a
                    class="btn-square btn btn-primary rounded-circle mx-1"
                    href=""
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a
                    class="btn-square btn btn-primary rounded-circle mx-1"
                    href=""
                  >
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="footer-item d-flex flex-column">
                <h4 class="mb-4 text-white">Company</h4>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> About
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Careers
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Blog
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Press
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Gift Cards
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Magazine
                </a>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="footer-item d-flex flex-column">
                <h4 class="mb-4 text-white">Support</h4>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Contact
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Legal Notice
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Privacy Policy
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Terms and Conditions
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Sitemap
                </a>
                <a href="">
                  <i class="fas fa-angle-right me-2"></i> Cookie policy
                </a>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="footer-item">
                <div class="row gy-3 gx-2 mb-4">
                  <div class="col-xl-6">
                    <form>
                      <div class="form-floating">
                        <select class="form-select bg-dark border" id="select1">
                          <option value="1">Arabic</option>
                          <option value="2">German</option>
                          <option value="3">Greek</option>
                          <option value="3">New York</option>
                        </select>
                        <label for="select1">English</label>
                      </div>
                    </form>
                  </div>
                  <div class="col-xl-6">
                    <form>
                      <div class="form-floating">
                        <select class="form-select bg-dark border" id="select1">
                          <option value="1">USD</option>
                          <option value="2">EUR</option>
                          <option value="3">INR</option>
                          <option value="3">GBP</option>
                        </select>
                        <label for="select1">$</label>
                      </div>
                    </form>
                  </div>
                </div>
                <h4 class="text-white mb-3">Payments</h4>
                <div class="footer-bank-card">
                  <a href="#" class="text-white me-2">
                    <i class="fab fa-cc-amex fa-2x"></i>
                  </a>
                  <a href="#" class="text-white me-2">
                    <i class="fab fa-cc-visa fa-2x"></i>
                  </a>
                  <a href="#" class="text-white me-2">
                    <i class="fas fa-credit-card fa-2x"></i>
                  </a>
                  <a href="#" class="text-white me-2">
                    <i class="fab fa-cc-mastercard fa-2x"></i>
                  </a>
                  <a href="#" class="text-white me-2">
                    <i class="fab fa-cc-paypal fa-2x"></i>
                  </a>
                  <a href="#" class="text-white">
                    <i class="fab fa-cc-discover fa-2x"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid copyright text-body py-4">
        <div class="container">
          <div class="row g-4 align-items-center">
            <div class="col-md-6 text-center text-md-end mb-md-0">
              <i class="fas fa-copyright me-2"></i>
              <a class="text-white" href="#">
                Your Site Name
              </a>
              , All right reserved.
            </div>
            <div class="col-md-6 text-center text-md-start"></div>
          </div>
        </div>
      </div>

      <a
        href="#"
        class="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"
      >
        <i class="fa fa-arrow-up"></i>
      </a>
    </div>
  );
}
