import {Link, Links} from 'react-router-dom'
import {Button} from '@mui/material';

export default function About() {
    return (
        <div>

     

       {/*  <div class="container-fluid bg-primary px-5 d-none d-lg-block">
            <div class="row gx-0">
                <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                    <div class="d-inline-flex align-items-center" style={{height: "45px;"}}>
                        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-twitter fw-normal"></i></a>
                        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-facebook-f fw-normal"></i></a>
                        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-linkedin-in fw-normal"></i></a>
                        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-instagram fw-normal"></i></a>
                        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href=""><i class="fab fa-youtube fw-normal"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 text-center text-lg-end">
                    <div class="d-inline-flex align-items-center" style={{height: "45px;"}}>
                        <a href="#"><small class="me-3 text-light"><i class="fa fa-user me-2"></i>Register</small></a>
                        <a href="#"><small class="me-3 text-light"><i class="fa fa-sign-in-alt me-2"></i>Login</small></a>
                        <div class="dropdown">
                            <a href="#" class="dropdown-toggle text-light" data-bs-toggle="dropdown"><small><i class="fa fa-home me-2"></i> My Dashboard</small></a>
                            <div class="dropdown-menu rounded">
                                <a href="#" class="dropdown-item"><i class="fas fa-user-alt me-2"></i> My Profile</a>
                                <a href="#" class="dropdown-item"><i class="fas fa-comment-alt me-2"></i> Inbox</a>
                                <a href="#" class="dropdown-item"><i class="fas fa-bell me-2"></i> Notifications</a>
                                <a href="#" class="dropdown-item"><i class="fas fa-cog me-2"></i> Account Settings</a>
                                <a href="#" class="dropdown-item"><i class="fas fa-power-off me-2"></i> Log Out</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <Link to="/" class="navbar-brand p-0">
            <h1 class="m-0">
              <img src={require("../images/LOGO.jpg")} alt="Logo" />
              Loreto Transfer
            </h1>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-0">
              <Link to="/" class="nav-item nav-link ">
                Home
              </Link>
              <Link to="/vehicles" class="nav-item nav-link ">
                Vehicles
              </Link>
              <Link to="/about" class="nav-item nav-link active">
                About us
              </Link>
              <Link to="/rates" class="nav-item nav-link ">
                Rates
              </Link>

              <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Activity
                </a>
                <div class="dropdown-menu m-0">
                  <Link to="/activity/water" class="dropdown-item">
                    Water activities
                  </Link>
                  <Link to="/activity" class="dropdown-item">
                    Land activities
                  </Link>
                </div>
              </div>

              <Link to="/contact" class="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Button variant="contained" sx={{ backgroundColor: "#000" }}>
              Book Now
            </Button>
          </div>
        </nav>
        </div>

    



        <div class="container-fluid aboutbg bg-breadcrumb">
            <div class="container text-center py-5" style={{maxWidth: "900px"}}>
                <h3 class="text-white display-3 mb-4">About Us</h3>
                <ol class="breadcrumb justify-content-center mb-0">
                    <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li class="breadcrumb-item active text-white">About</li>
                </ol>    
            </div>
        </div>
        <div class="container-fluid about py-5">
  <div class="container py-5">
    <div class="row g-5 align-items-center">
      <div class="col-lg-5">
        <div class="h-100">
          <img
            src={require("../images/contact-bg.jpg")}
            class="img-fluid w-100 h-100"
            alt=""
          />
        </div>
      </div>
      <div
        class="col-lg-7"
      
      >
        <h5 class="section-about-title pe-3">About Us</h5>
        <h1 class="mb-4">
          Welcome to <span class="text-dark">Loreto Transfer</span>
        </h1>
        <p class="mb-4">
          At <strong>Loreto Transfer</strong>, we believe that your journey is as important as your destination. We’re passionate about making your travel experience unforgettable by offering exclusive transportation services to and from Cabo San Lucas and its beautiful surroundings.
        </p>
        <p class="mb-4">
          Whether you're visiting for the first time or returning for another adventure, we are committed to providing you with the highest level of comfort, convenience, and affordability. Our carefully curated fleet of vehicles and experienced chauffeurs ensure that your travel is seamless, safe, and enjoyable from the moment you arrive.
        </p>
        <div class="row gy-2 gx-4 mb-4">
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>First Class Flights</strong> – We ensure your journey starts in style with luxurious, first-class flights to Cabo San Lucas.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>Handpicked Hotels</strong> – Relax in the finest hotels, selected for their comfort, luxury, and proximity to key destinations.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>5 Star Accommodations</strong> – Stay in exceptional, 5-star accommodations for the ultimate relaxation after your journey.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>Latest Model Vehicles</strong> – Travel in the newest, most luxurious vehicles for a comfortable and smooth ride throughout your trip.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>150 Premium City Tours</strong> – Explore the beauty of Cabo with curated, premium city tours that ensure you don’t miss any of the hidden gems.
    </p>
  </div>
  <div class="col-sm-6">
    <p class="mb-0">
      <i class="fa fa-arrow-right text-dark me-2"></i><strong>24/7 Service</strong> – Our dedicated team is always available to assist you at any hour, ensuring a seamless travel experience from start to finish.
    </p>
  </div>
</div>

      </div>
    </div>
  </div>
</div>
<div class="container-fluid bg-light service py-5">
        <div class="container py-5">
        <div class="mx-auto text-center mb-5" style={{maxWidth: "900px"}}>
                    <h1 class="section-title px-3 mb-0">Our Values</h1>
                </div>
          <div class="row g-4">
            <div class="col-lg-6">
              <div class="row g-4">
                <div class="col-12">
                  <div class="service-content-inner d-flex align-items-center bg-white border border-dark rounded pe-0">
                    <div class="service-content text-justify p-4">
                      <div class="service-icon p-4">
                        <img
                          src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2023/10/easy_online_booking.jpg/W=450,dpr=2,fit=cover"
                          class="img-service"
                        />
                      </div>
                      <h5 class="mb-4">Uninterrupted Convenience</h5>
                      <p class="mb-0">
                        Booking Transfeero's hourly service means you can have a
                        chauffeur ready and waiting to take you to your next
                        destination, regardless of how many stops you need to
                        make. No more waiting around for rides or planning your
                        day around the availability of transport options. Our
                        chauffeur stays with you, providing continuous,
                        uninterrupted service.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="service-content-inner d-flex align-items-center  bg-white border border-dark rounded pe-0">
                    <div class="service-content text-justify p-4">
                      <div class="service-icon p-4">
                        <img
                          src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2023/10/flight_traking-1.jpg/W=450,dpr=2,fit=cover"
                          class="img-service"
                        />
                      </div>
                      <h5 class="mb-4">A Tailored Itinerary</h5>
                      <p class="mb-0">
                        Our clients value the ability to craft their own
                        itinerary without being bound to a fixed schedule. As
                        you book our Hourly Chauffeur Service, you can set the
                        pace of the day. Decide when to depart, where to go, and
                        how long to stay. Whether it's impromptu detours to
                        hidden gems or extended stays at a business engagement,
                        the flexibility is yours.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="row g-4">
                <div class="col-12">
                  <div class="service-content-inner d-flex align-items-center  bg-white border border-dark rounded  pe-0">
                    <div class="service-content text-justify p-4">
                      <div class="service-icon p-4">
                        <img
                          src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2023/10/clear_price-1.jpg/W=450,dpr=2,fit=cover"
                          class="img-service"
                        />
                      </div>
                      <h5 class="mb-4">Cost-Effectiveness</h5>
                      <p class="mb-0">
                        With our transparent and competitive pricing, you'll
                        find our Hourly Chauffeur Service to be a cost-effective
                        option. There are no hidden charges, and you benefit
                        from the advantage of having a car on standby without
                        the meter running endlessly. You pay for the time you
                        book, giving you control over your travel budget.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="service-content-inner d-flex align-items-center bg-white border border-dark rounded  pe-0">
                    <div class="service-content text-justify p-4">
                      <div class="service-icon p-4">
                        <img
                          src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2023/10/pro_driver.jpg/W=450,dpr=2,fit=cover"
                          class="img-service"
                        />
                      </div>
                      <h5 class="mb-4">Top-Notch Professional Chauffeurs</h5>
                      <p class="mb-0">
                        Our professional chauffeurs are not only skilled drivers
                        but also understand the importance of customer service.
                        They are local experts and can provide valuable insights
                        about the area, recommend places to visit, and assist
                        with any particular requirements you may have throughout
                        your trip.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="container-fluid footer py-5">
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="footer-item d-flex flex-column">
                            <h4 class="mb-4 text-white">Get In Touch</h4>
                            <a href=""><i class="fas fa-home me-2"></i> 123 Street, New York, USA</a>
                            <a href=""><i class="fas fa-envelope me-2"></i> info@example.com</a>
                            <a href=""><i class="fas fa-phone me-2"></i> +012 345 67890</a>
                            <a href="" class="mb-3"><i class="fas fa-print me-2"></i> +012 345 67890</a>
                            <div class="d-flex align-items-center">
                                <i class="fas fa-share fa-2x text-white me-2"></i>
                                <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="footer-item d-flex flex-column">
                            <h4 class="mb-4 text-white">Company</h4>
                            <a href=""><i class="fas fa-angle-right me-2"></i> About</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Careers</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Blog</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Press</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Gift Cards</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Magazine</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="footer-item d-flex flex-column">
                            <h4 class="mb-4 text-white">Support</h4>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Contact</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Legal Notice</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Privacy Policy</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Terms and Conditions</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Sitemap</a>
                            <a href=""><i class="fas fa-angle-right me-2"></i> Cookie policy</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="footer-item">
                            <div class="row gy-3 gx-2 mb-4">
                                <div class="col-xl-6">
                                    <form>
                                        <div class="form-floating">
                                            <select class="form-select bg-dark border" id="select1">
                                                <option value="1">Arabic</option>
                                                <option value="2">German</option>
                                                <option value="3">Greek</option>
                                                <option value="3">New York</option>
                                            </select>
                                            <label for="select1">English</label>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-xl-6">
                                    <form>
                                        <div class="form-floating">
                                            <select class="form-select bg-dark border" id="select1">
                                                <option value="1">USD</option>
                                                <option value="2">EUR</option>
                                                <option value="3">INR</option>
                                                <option value="3">GBP</option>
                                            </select>
                                            <label for="select1">$</label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <h4 class="text-white mb-3">Payments</h4>
                            <div class="footer-bank-card">
                                <a href="#" class="text-white me-2"><i class="fab fa-cc-amex fa-2x"></i></a>
                                <a href="#" class="text-white me-2"><i class="fab fa-cc-visa fa-2x"></i></a>
                                <a href="#" class="text-white me-2"><i class="fas fa-credit-card fa-2x"></i></a>
                                <a href="#" class="text-white me-2"><i class="fab fa-cc-mastercard fa-2x"></i></a>
                                <a href="#" class="text-white me-2"><i class="fab fa-cc-paypal fa-2x"></i></a>
                                <a href="#" class="text-white"><i class="fab fa-cc-discover fa-2x"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container-fluid copyright text-body py-4">
            <div class="container">
                <div class="row g-4 align-items-center">
                    <div class="col-md-6 text-center text-md-end mb-md-0">
                        <i class="fas fa-copyright me-2"></i><a class="text-white" href="#">Your Site Name</a>, All right reserved.
                    </div>
                    <div class="col-md-6 text-center text-md-start">
                        Designed By <a class="text-white" href="https://htmlcodex.com">HTML Codex</a>
                    </div>
                </div>
            </div>
        </div>

        <a href="#" class="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i class="fa fa-arrow-up"></i></a>   

        
    
    </div>
    )
}